import React from 'react';
import {
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

const BlogSubscribe = ({
  className,
  size,
  round,
  url,
  hashtag,
  title,
  description,
  iconFillColor,
  bgStyle,
}) => {
  return (
    <div className={`flex justify-center space-x-8 ${className}`}>
      <EmailShareButton url={url} subject={title} body={description}>
        <EmailIcon
          size={size}
          round={round}
          iconFillColor={iconFillColor}
          bgStyle={bgStyle}
        />
      </EmailShareButton>
      <LinkedinShareButton url={url} title={title} summary={description}>
        <LinkedinIcon
          size={size}
          round={round}
          iconFillColor={iconFillColor}
          bgStyle={bgStyle}
        />
      </LinkedinShareButton>
      <FacebookShareButton url={url} quote={title} hashtag={hashtag}>
        <FacebookIcon
          size={size}
          round={round}
          iconFillColor={iconFillColor}
          bgStyle={bgStyle}
        />
      </FacebookShareButton>
      {/* <FacebookMessengerShareButton url={url}>
        <FacebookMessengerIcon
          size={size}
          round={round}
          iconFillColor={iconFillColor}
          bgStyle={bgStyle}
        />
      </FacebookMessengerShareButton> */}
      <TwitterShareButton url={url} hashtags={[hashtag]} title={title}>
        <TwitterIcon
          size={size}
          round={round}
          iconFillColor={iconFillColor}
          bgStyle={bgStyle}
        />
      </TwitterShareButton>
    </div>
  );
};

export default BlogSubscribe;
