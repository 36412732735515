import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const BlogSubscribe = ({ className }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      strapiArticleSubscription {
        title
        button
        description
        placeholder
      }
    }
  `);

  const {
    title,
    button,
    description,
    placeholder,
  } = data.strapiArticleSubscription;

  return (
    <div className={`${className}`}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:flex lg:items-center">
        <div className="">
          <h2
            className="text-3xl font-bold tracking-tight text-blue-50 sm:text-4xl"
            id="newsletter-headline"
          >
            {title}
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-blue-200">
            {description}
          </p>
        </div>
        <div className="mt-8 lg:mt-3 lg:ml-20">
          <form
            className="sm:flex"
            method="POST"
            action="https://formspree.io/f/xknkgwkl"
          >
            <input type="text" name="_gotcha" className="hidden" />
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white focus:border-blue-50 sm:max-w-xs rounded-md"
              placeholder={placeholder}
            />
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <button
                type="submit"
                className="w-full px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-blue-500"
              >
                {button}
              </button>
            </div>
          </form>
          {/* <p className="mt-3 text-sm text-gray-300">
            We care about the protection of your data. Read our
            <a href="#" className="text-white font-medium underline">
              Privacy Policy.
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default BlogSubscribe;
