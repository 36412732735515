import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const BlogCard = ({
  title,
  slug,
  categoryName,
  categorySlug,
  authorName,
  authorImage,
  authorSlug,
  image,
  description,
  publishedDate,
  className,
}) => {
  return (
    <div
      className={
        'flex flex-col overflow-hidden bg-blue-50 border-blue-50 border-2 rounded-md shadow-md dark:bg-gray-800 ' +
        className
      }
    >
      <Link to={'/' + slug}>
        <Img className="object-cover w-full h-64" alt="Article" fluid={image} />
      </Link>
      <div className="p-6 flex flex-col flex-grow">
        <div className="flex-grow">
          <Link
            className="text-xs font-serif text-blue-700 uppercase hover:text-blue-600 hover:underline"
            to={'/' + categorySlug}
          >
            {categoryName}
          </Link>
          <Link
            to={'/' + slug}
            className="block mt-2 text-2xl font-serif font-semibold text-blue-700 dark:text-white hover:text-blue-600 hover:underline"
          >
            {title}
          </Link>
          <p className="mt-2 text-sm text-blue-900 dark:text-gray-400">
            {description}
          </p>
        </div>

        <div className="mt-8">
          <div className="flex items-center">
            <div className="flex items-center">
              <Link to={'/' + authorSlug}>
                <Img
                  className="object-cover h-10 rounded-full"
                  alt="Avatar"
                  fixed={authorImage}
                />
              </Link>
              <Link
                to={'/' + authorSlug}
                className="mx-2 text-blue-700 hover:underline hover:text-blue-500"
              >
                {authorName}
              </Link>
            </div>
            <span className="mx-1 mt-0.5 text-xs text-blue-600">
              {publishedDate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
