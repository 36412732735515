import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import Img from 'gatsby-image';
import BlogCard from '../components/blog-card';
import SEO from '../components/seo';
import BlogSubscribe from '../components/blog-subscribe';
import BlogShare from '../components/blog-share';

export default function Article({ data, location }) {
  const {
    title,
    description,
    content,
    content_images,
    publishedAt,
    author,
    image,
    category,
  } = data.strapiArticle;

  return (
    <Layout>
      <SEO title={title} />
      <div className="angled-bottom pb-32 text-center pt-16">
        <h5 className="pb-10 pt-14 text-blue-100 small-caps text-sm lg:text-base ">
          <Link
            className="hover:underline hover:text-blue-200"
            to={'/' + category.slug}
          >
            {category.name}
          </Link>
        </h5>

        <h1 className="text-blue-100 text-3xl lg:text-4xl font-bold">
          {title}
        </h1>
        <p className="text-sm text-blue-100 pt-8 pb-6 small-caps lg:text-base">
          <Link
            className="hover:text-blue-200 hover:underline"
            to={'/' + author.profileSlug}
          >
            {author.name}
          </Link>{' '}
          &middot; {formatDate(publishedAt)}
        </p>
        <Link to={'/' + author.profileSlug}>
          <Img
            className="border-2 border-blue-100 rounded-full mx-auto shadow-inner"
            style={{ display: 'block' }}
            fixed={author.picture.childImageSharp.fixed}
          />
        </Link>
      </div>
      <div className="px-8 max-w-xl lg:max-w-2xl mx-auto">
        <Img
          className="-mt-16 border-2 border-gray-100 rounded"
          fluid={image.childImageSharp.fluid}
        />
        <div className="mt-10 w-8 h-1 bg-blue-700 mx-auto" />
        <ReactMarkdownWithHtml
          children={content}
          allowDangerousHtml
          renderers={{
            image: ({ src, alt }) => {
              const image = content_images.find(
                element => element.base === src
              );
              console.log(image);
              return <Img fluid={image.childImageSharp.fluid} alt={alt} />;
            },
          }}
          className="pt-8 pb-6 lg:text-lg prose text-blue-800"
        ></ReactMarkdownWithHtml>
        <div className="mb-6 w-8 h-1 bg-blue-700 mx-auto" />
        <BlogShare
          className="pt-2 pb-6"
          size={40}
          round={true}
          url={location.href}
          hashtag={camalize(category.name)}
          title={title}
          description={description}
          iconFillColor="#F4F6F6"
          bgStyle={{ fill: '#0F2D57' }}
        />
      </div>
      <div className="angled-top p-6 pt-24 flex flex-col place-items-center">
        <BlogSubscribe className="" />
        <h5 className="border-b-2 text-blue-100 small-caps pb-4 mb-6 mt-10 text-lg max-w-2xl lg:max-w-7xl w-full">
          Related Articles
        </h5>
        <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-6 max-w-2xl lg:max-w-7xl">
          {data.allStrapiArticle.edges.map(({ node }, index) => (
            <div className="flex-1">
              <BlogCard
                key={index}
                title={node.title}
                slug={node.slug}
                categoryName={node.category.name}
                categorySlug={node.category.slug}
                authorName={node.author.name}
                authorImage={node.author.picture.childImageSharp.fixed}
                authorSlug={node.author.profileSlug}
                image={node.image.childImageSharp.fluid}
                description={node.description}
                publishedDate={formatDate(node.publishedAt)}
              ></BlogCard>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

function camalize(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

const formatDate = dateString => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const query = graphql`
  query($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      status
      description
      content
      content_images {
        childImageSharp {
          original {
            src
          }
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        base
      }
      publishedAt
      author {
        picture {
          childImageSharp {
            fixed(quality: 90, width: 55, height: 55) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        name
        profileSlug
      }
      image {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      category {
        name
        slug
      }
    }
    allStrapiArticle(
      limit: 3
      sort: { order: DESC, fields: publishedAt }
      filter: { slug: { ne: $slug }, status: { eq: "published" } }
    ) {
      edges {
        node {
          title
          author {
            name
            profileSlug
            picture {
              childImageSharp {
                fixed(quality: 90, width: 48, height: 48) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          category {
            name
            slug
          }
          description
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          publishedAt
          slug
        }
      }
    }
  }
`;
